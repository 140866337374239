<template>
    <div class="flex flex-no-wrap h-6 mb-2">
        <div class="w-28 flex-none mr-1" />
        <div class=" mx-1 w-full" />
        <div class="mx-1 w-4/9 flex-none flex p-0">
            <div class="w-full px-2 bg-good-30 border-b border-good text-good flex items-center">
                <div class="font-semibold">25%</div>
                <div class="text-white text-tiny ml-auto">
                    {{ row['25%'] | reduceBigNumbers(2) }}€
                </div>
            </div>
            <div class="w-1 flex-none" />
            <div class="w-full px-2 bg-success-30 border-b border-success text-success flex items-center">
                <div class="font-semibold">50%</div>
                <div class="text-white text-tiny ml-auto">
                    {{ row['50%'] | reduceBigNumbers(2) }}€
                </div>
            </div>
            <div class="w-1 flex-none" />
            <div class="w-full px-2 bg-warning-30 border-b border-warning text-warning flex items-center">
                <div class="font-semibold">75%</div>
                <div class="text-white text-tiny ml-auto">
                    {{ row['75%'] | reduceBigNumbers(2) }}€
                </div>
            </div>
            <div class="w-1 flex-none" />
            <div class="w-full p-2 bg-danger-30 border-b border-danger text-danger flex items-center">
                <div class="font-semibold">100%</div>
                <div class="text-white text-tiny ml-auto">
                    {{ row['100%'] | reduceBigNumbers(2) }}€
                </div>
            </div>
            <div class="w-24 flex-none" />
        </div>
        <div class=" mx-1 w-full" />
        <div class="w-28 flex-none ml-1" />
    </div>
</template>

<script>
    export default {
        name: "SummaryOngoing",
        props: ['row']
    }
</script>